<template>
    <div>
      <v-card elevation="0">
        <v-card-title>
          <b class="titulo-header-page">Relatório Mensal - Quantidade de Novos Dependentes Adicionados</b>
        </v-card-title>
        <div class="ml-5 mr-5 d-flex">
          <v-row dense>
            <v-col cols="12" md="3">
              <v-text-field class="input" color="#007744" label="Data inicial:" v-model="params.inicio"
               required type="date" outlined
                dense></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field class="input" v-model="params.fim"
                color="#007744" type="date" label="Data final:" required outlined
                dense></v-text-field>
            </v-col>
          </v-row>
        </div>
        <div class="spacer ml-5 mr-5 d-flex">
          <v-btn color="#007744" class="mr-4 white--text" small
            @click="gerarRelatorio()">
            <v-icon dark> mdi-plus </v-icon>
            Gerar Relatório
          </v-btn>

          <template>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="warning" class="mr-4 white--text" small @click="limpar"
                  v-bind="attrs" v-on="on">
                  <v-icon dark> mdi-delete </v-icon>
                </v-btn>
              </template>
              <span>Limpar Filtros</span>
            </v-tooltip>
          </template>
        </div>
  
        <loading v-if="carregamento" />
  
        <div v-else-if="relatorioGerado">
            <div class="margin-top-quantidade">
                <v-row dense>
                    <v-col cols="12">
                        <widget icon="mdi-paw" class="centralizar-informacoes" subTitle="Total de Adicionais incluidos entre as datas selecionadas"
                            color="blue darken-4" :title="relatorio" />
                    </v-col>
                </v-row>
            </div>
        </div>
        <found v-else />
      </v-card>
    </div>
  </template>
  
  <script>
  var moment = require("moment");
  moment.locale("pt-br");

  import axios from '../../axios/service_private.js';
  
  export default {
    data() {
      return {
        carregamento: false,
        relatorio: 0,
        params: {
          inicio: moment().startOf("month").format("YYYY-MM-DD"),
          fim: moment().format("YYYY-MM-DD")
        },
        relatorioGerado: null
      };
    },
    methods: {
        limpar() {
            this.relatorioGerado = false;
            this.relatorio = 0;
        },
        async gerarRelatorio() {
            this.relatorioGerado = true;
            this.carregamento = true;
    
            let params = {
                data_inicial: this.params.inicio,
                data_fim: this.params.fim
            };
    
            try {

                const relatorioSelecionado = await axios.get('/api/v1/pet-primavera/relatorio/novos-dependentes-mensais', { params });
        
                if (relatorioSelecionado.data.status) {
                    this.relatorio = relatorioSelecionado.data.data;
                }
    
            } catch (res) {
                this.$toast(res.response.data.message, {
                    type: "error",
                });
            }
            this.carregamento = false;
        },
        getCurrentDateFormatted() {
            this.params.inicio = moment().startOf("month").format("YYYY-MM-DD");
            this.params.fim = moment().format("YYYY-MM-DD");
        },
        formatValor(number) {
            return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        },
        setup() {
            this.$store.dispatch("verifyToken");
        },
    },
    mounted() {
      this.setup();
    },
  };
  </script>

  <style scoped>
    .margin-top-quantidade {
        margin-top: 20px;
    }
  </style>
  